import { t } from "@lingui/macro";
import PromotionIcon from "../icons/local-popup-icon";
import KioskIcon from "../icons/kiosk-icon";
import AdvertisingIcon from "../icons/ad-icon";
import EventsIcon from "../icons/events-icon";
import MallRetailIcon from "../icons/mall-retail";
import CustomerAcquisitionIcon from "../icons/customer-acquisition";
import AmbientMediaIcon from "../icons/ambient-media";
import VendingIcon from "../icons/vending";

/**
 * Spots feature utils
 */

export const getIconFromSpotSlug = (slug) => {
  switch (slug) {
    case "advertising":
      return AdvertisingIcon;
    case "retail":
      return PromotionIcon;
    case "events":
      return EventsIcon;
    case "mall-retail":
      return MallRetailIcon;
    case "customer-acquisition":
      return CustomerAcquisitionIcon;
    case "ambient-media":
      return AmbientMediaIcon;
    case "brand":
      return AdvertisingIcon;
    case "vending":
      return VendingIcon;
    case "promotion":
    default:
      return KioskIcon;
  }
};

export const getSpacePriceConfigFromSpot = (spot) => {
  switch (spot) {
    case "retail":
      return "monthly";
    case "promotion":
      return "daily";
    case "advertising":
      return "daily";
    default:
      return "daily";
  }
};

export const getSpacePriceFromConfig = (space, priceConfig) => {
  switch (priceConfig) {
    case "daily":
      return space.display_price;
    case "weekly":
      return space.full_week_price;
    case "monthly":
      return space.full_month_price;
    default:
      return space.display_price;
  }
};

export const getAdSpacePriceFromConfig = (adspace, priceConfig) => {
  switch (priceConfig) {
    case "daily":
      return adspace.display_price;
    case "biweekly":
      return adspace.fortnight_price;
    case "monthly":
      return adspace.month_price;
    default:
      return adspace.display_price;
  }
};

export const SpacePriceConfigTranslations = {
  daily: t`día`,
  weekly: t`semana`,
  monthly: t`mes`,
};

export const AdSpacePriceConfigTranslations = {
  daily: t`día`,
  biweekly: t`quincenal`,
  monthly: t`mes`,
};
