import axios from "axios";
import { dateDiff, DIFFERENCE_FORMAT, formatDateUtc } from "../utils/dateUtils";
import {
  adapterColumnsAdSpaces,
  adapterColumnsBookings,
  adapterColumnsSpaces,
  adapterColumnsAssets,
  adapterColumnsLandlords,
  adapterColumnsUsers,
  adapterColumnsCompanies,
} from "../utils/adapter-columns";
import authApi from "./auth";
import { getRowsFromWindowSize, getBiggerRowsFromWindowSize } from "../utils";
import { CONSULT_STATUS, CONSULT_STATUS_REAL, CONSULT_STATUS_VIRTUAL } from "../redux/reducers";
import config from "../config.js";
import * as invoicingReport from "./invoicing-report";
import * as performanceAnalyzer from "./performance-analyzer";
import * as services from "./services";
import * as timelineVacancy from "./timeline-vacancy";
import * as attachment from "./attachment";
import * as bookingOperation from "./booking-operation";
import * as imports from "./imports";
import * as v2 from "./v2";

const BASE_URL_API = `${config.API_URL}/api/v1`;

axios.interceptors.request.use((config) => {
  const token = `Bearer ${localStorage.getItem("token")}`;
  config.headers.Authorization = token;

  config.bacresponse = {
    start: new Date(),
    method: config.method,
    url: config.url,
  };
  return config;
});
axios.interceptors.response.use(
  (response) => {
    const { bacresponse } = response.config;
    const duration = dateDiff(bacresponse.start, new Date(), DIFFERENCE_FORMAT.MILLISECOND);
    return response;
  },
  (error) => {
    if (error.response?.status === 400 && error.response?.data?.message === "token_is_expired") {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      sessionStorage.setItem("disconectedByInactivity", true);
      window.location.replace("/");
    }
    if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
      if (error.response.data?.message === "invalid_token") {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        window.location.replace("/");
      }
    }
    return error;
  },
);

const handleSpaceFilters = (filters) => {
  let stringFilters = "";

  if (filters.priceStart) stringFilters += `&min_price=${filters.priceStart}`;
  if (filters.priceEnd) stringFilters += `&max_price=${filters.priceEnd}`;
  if (filters.sizeStart) stringFilters += `&min_size=${filters.sizeStart}`;
  if (filters.sizeEnd) stringFilters += `&max_size=${filters.sizeEnd}`;
  if (filters.types && filters.types.length > 0) {
    stringFilters += `&types=`;
    filters.types.map((t) => {
      stringFilters += `${t.name},`;
    });
  }
  if (filters.uses && filters.uses.length > 0) {
    stringFilters += `&usenames=`;
    filters.uses.map((u) => {
      stringFilters += `${u.name},`;
    });
  }
  if (filters.assets && filters.assets.length > 0) {
    stringFilters += `&assets=`;
    filters.assets.map((a) => {
      stringFilters += `${a.id},`;
    });
  }
  if (filters.countries && filters.countries.length > 0) {
    stringFilters += `&countries=`;
    filters.countries.map((c) => {
      stringFilters += `${c.id},`;
    });
  }
  if (filters.cities && filters.cities.length > 0) {
    stringFilters += `&cities=`;
    filters.cities.map((c) => {
      stringFilters += `${c.id},`;
    });
  }
  if (filters.services && filters.services.length > 0) stringFilters += `&services=${filters.services.join(",")}`;
  if (filters.search && filters.search.length != "") {
    stringFilters += `&search=${filters.search}`;
  }

  return stringFilters;
};

const handleAdSpaceFilters = (filters) => {
  let stringFilters = "";
  if (filters.start) {
    stringFilters += `&start=${filters.start}`;
  }
  if (filters.end) {
    stringFilters += `&end=${filters.end}`;
  }
  if (filters.assets) {
    stringFilters += `&assets=`;
    filters.assets.forEach((a) => {
      stringFilters += `${a.id},`;
    });
  }
  if (filters.cities) {
    stringFilters += `&cities=`;
    filters.cities.forEach((c) => {
      stringFilters += `${c.id},`;
    });
  }
  if (filters.search) {
    stringFilters += `&search=${filters.search}`;
  }

  return stringFilters;
};

const extendBooking = ({ bookingId, end, paymentPlan, periodPrice, price }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/extend/`, {
    end,
    payment_plan: paymentPlan,
    period_price: periodPrice,
    price,
  });

const getFilterFromState = (state) => {
  let mainFilter = "";
  if (CONSULT_STATUS_REAL.some((s) => s === state)) {
    mainFilter = `status=${state}`;
  } else if (CONSULT_STATUS_VIRTUAL.some((s) => s === state)) {
    // TODO: backend pide filtro de tipo in-progress, y las rutas internas
    // nuestras son inprogress
    mainFilter = state === "inprogress" ? `filter=in-progress` : `filter=${state}`;
  }

  return mainFilter;
};

const getSpaceBookings = async ({ spaceId = "", start, end, page = 1, pageSize = getRowsFromWindowSize() }) =>
  axios.get(
    `${BASE_URL_API}/spaces/${spaceId}/inprogressbookings/?page=${page}&page_size=${pageSize}&start=${start}&end=${end}`,
  );

const getFeatureFlags = async () => axios.get(`${BASE_URL_API}/featureflags`);

const getBookingDetailedPrice = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/detailedprice`);

const getBookingCanRescind = ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/canrescind`);

const rescindBooking = ({ bookingId, end }) => {
  if (end) {
    return axios.post(`${BASE_URL_API}/bookings/${bookingId}/rescind`, {
      end,
    });
  }
  return axios.post(`${BASE_URL_API}/bookings/${bookingId}/rescind`, {});
};

const getBookingInvoices = async ({ bookingId, wholeChain = true }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/currentaccount?wholeChain=${wholeChain}`);

const addBookingCreditNote = async ({ bookingId, price, concept, date }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/creditnote/`, {
    price: Number(price),
    concept,
    date: date ? formatDateUtc(date, "YYYY-MM-DD") : null,
  });

const addBookingDebitNote = async ({ bookingId, price, concept, date }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/addinvoice/`, {
    price: Number(price),
    concept,
    date: date ? formatDateUtc(date, "YYYY-MM-DD") : null,
  });

// going to replace addBokingCreditNote and addBookingDebitNote
const addBookingTransaction = async ({ bookingId, amount, description, date, type = "invoice" }) =>
  axios.post(`${BASE_URL_API}/invoicetransactions/booking/${bookingId}`, {
    amount: Number(amount),
    description,
    date: date ? formatDateUtc(date, "YYYY-MM-DD") : null,
    type,
    // additionalDescription
  });
// going to replace editCreditNote, etc
const editBookingTransaction = async (transactionId, { amount, description, date, type = "invoice" }) =>
  axios.patch(`${BASE_URL_API}/invoicetransactions/${transactionId}`, {
    amount: Number(amount),
    description,
    date: date ? formatDateUtc(date, "YYYY-MM-DD") : null,
    type,
  });

const createInvoiceFromTransaction = async (transactionId, data) =>
  axios.post(`${BASE_URL_API}/invoices/createfromtransaction/${transactionId}`, data, {
    validateStatus: (status) => {
      return status >= 200 && status < 500;
    },
  });

const generateInvoicePdf = async (invoiceId) => axios.post(`${BASE_URL_API}/invoices/${invoiceId}/generatepdf`, {});

const generateInvoiceReprocess = async (invoiceId) => axios.post(`${BASE_URL_API}/invoices/${invoiceId}/reprocess`, {});

const deleteBookingTransaction = async (transactionId) =>
  axios.delete(`${BASE_URL_API}/invoicetransactions/${transactionId}`, {});

const setBookingSpaceUse = async ({ bookingId, useId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/setuse`, {
    use_id: useId,
  });

const updateBookingParams = async ({ bookingId, irpf, iva, warranty, account, reference, applicationNumber }) => {
  const params = {};
  if (irpf) {
    params.irpf_percentage = irpf;
  }
  if (iva) {
    params.iva_percentage = iva;
  }
  if (warranty) {
    params.warranty = warranty;
  }
  if (account) {
    params.account = account;
  }
  if (reference) {
    params.reference = reference;
  }
  if (applicationNumber) {
    params.application_number = applicationNumber;
  }
  return axios.patch(`${BASE_URL_API}/bookings/${bookingId}/params`, {
    params,
  });
};

const updateAssetOptions = ({ asset, irpf, iva, warranty, account, generateProforma }) => {
  const params = {};
  if (irpf) {
    params.irpf_percentage = irpf;
  }
  if (iva) {
    params.iva_percentage = iva;
  }
  if (account) {
    params.account = account;
  }
  if (generateProforma) {
    params.generate_proforma = generateProforma;
  }
  if (typeof warranty === "boolean") {
    params.warranty = warranty;
  }
  return axios.patch(`${BASE_URL_API}/assets/${asset}/options`, {
    config: {
      ...params,
    },
  });
};

const updateAssetInvoiceOptions = (asset, invoiceOptions) => {
  return axios.patch(`${BASE_URL_API}/assets/${asset}/invoiceoptions`, invoiceOptions);
};

const getAdSpaceInprogressBookings = async ({
  adSpaceId = "",
  start,
  end,
  page = 1,
  pageSize = getRowsFromWindowSize(),
}) =>
  axios.get(
    `${BASE_URL_API}/adspaces/${adSpaceId}/inprogressbookings/?page=${page}&page_size=${pageSize}&start=${start}&end=${end}`,
  );

const setBookableItemCategory = async (bookableItemId, categoryId) =>
  axios.post(`${BASE_URL_API}/bookedbookables/${bookableItemId}/setcategory`, {
    bookable_category_id: categoryId,
  });

const generateNewBookingProforma = async ({ bookingId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/newproforma`, {});

const getUserInvoices = ({ userId, filters, pageSize = 0, page = 1 }) => {
  let stringFilters = "";
  if (filters.bookingId) {
    stringFilters += `&booking_id=${filters.bookingId}`;
  }

  return axios.get(`${BASE_URL_API}/user/${userId}/invoices?page_size=${pageSize}&page=${page}${stringFilters}`);
};

const getUsersList = ({ pageSize = getBiggerRowsFromWindowSize(), page, sort }) => {
  let url = `${BASE_URL_API}/user/admins/list?page_size=${pageSize}&page=${page}`;
  if (sort) {
    const sortKey = sort.name === "role" ? "profile.role" : sort.name;
    url += `&orderBy=${sortKey}&orderDir=${sort.direction}`;
  }
  return axios.get(url);
};

const acceptBooking = async ({ bookingId, confirmOn }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/accept`, { confirm_on: confirmOn });
const rejectBooking = async ({ bookingId }) => axios.post(`${BASE_URL_API}/bookings/${bookingId}/reject`, {});

const bookingSpecialPrice = async (id, price, paymentPlan) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/specialprice`, {
    price,
    payment_plan: paymentPlan,
  });

const startShowingBookingPrices = async (id) => axios.post(`${BASE_URL_API}/bookings/${id}/startshowingprices`, {});

const setBookingRate = (id, data) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/rate`, {
    ...data,
  });

const getAdminSpace = async (id) => axios.get(`${BASE_URL_API}/admin/spaces/${id}`);

const postSpace = async (data = {}) => {
  return axios.post(`${BASE_URL_API}/spaces/`, data);
};

const putSpace = async (id, data) => {
  let validData = {};
  Object.keys(data).forEach((att) => {
    if (data[att] || typeof data[att] === "number") {
      validData = {
        ...validData,
        [att]: data[att],
      };
    }
  });
  return axios.patch(`${BASE_URL_API}/spaces/${id}`, validData);
};

const setSpaceSpots = async (id, tags) =>
  axios.post(`${BASE_URL_API}/tags/space/${id}`, {
    tags,
  });

const setSpaceAttachments = async (id, attachments) =>
  axios.post(`${BASE_URL_API}/spaces/${id}/attachments`, {
    attachments,
  });

const attachFileToBooking = async (id, file) => axios.post(`${BASE_URL_API}/bookings/${id}/addattachment`, file);

const detachFileToBooking = async (id, fileId) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/removeattachment`, {
    attachment_id: fileId,
  });

// contracts
const sendBookingContractToDocusign = (bookingContractId) => {
  return axios.post(`${BASE_URL_API}/bookingscontract/sign/${bookingContractId}`, {});
};

const createBookingContract = async (bookingId) =>
  axios.post(`${BASE_URL_API}/bookingscontract/createempty/${bookingId}`, {});

const createBookingContractWithTemplate = async (bookingId, contractTemplate) =>
  axios.post(`${BASE_URL_API}/bookingscontract/create/${bookingId}/${contractTemplate?.id}`, {});

const updateBookingContract = async (bookingContract) =>
  axios.post(`${BASE_URL_API}/bookingscontract/update/${bookingContract.id}`, {
    ...bookingContract,
  });

const removeBookingContract = async (bookingContract) =>
  axios.delete(`${BASE_URL_API}/bookingscontract/deleteuploaded/${bookingContract}`);

const replaceBookingSignedContract = async (bookingContractId, file) =>
  axios.post(`${BASE_URL_API}/bookingscontract/signedfileuploadedurl/${bookingContractId}`, {
    url: file,
  });

const uploadBookingContractTemplate = async (bookingContractId, file) =>
  axios.post(`${BASE_URL_API}/bookingscontract/fileuploadedurl/${bookingContractId}`, {
    url: file,
  });

const removeBookingContractGenerated = async (bookingContractId) =>
  axios.delete(`${BASE_URL_API}/bookingscontract/generated/${bookingContractId}`);

const removeBookingContractTemplate = async (bookingContractId) =>
  axios.delete(`${BASE_URL_API}/bookingscontract/uploadfile/${bookingContractId}`);

// to be deprecated
const updateContractModel = async (id, contractModelId) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/updatecontractmodel`, {
    contract_model_id: contractModelId,
  });

const updateContractData = async (id, data, dataOwner = "admin") => {
  const url =
    dataOwner === "admin"
      ? `${BASE_URL_API}/bookings/${id}/admincontractdata`
      : `${BASE_URL_API}/bookings/${id}/customercontractdata`;

  return axios.post(url, {
    data,
  });
};

const contractPreviewGenerate = async (bookingContractId) =>
  axios.post(`${BASE_URL_API}/bookingscontract/generate/${bookingContractId}`, {});

const hideListing = async (id) => axios.post(`${BASE_URL_API}/spaces/${id}/nonsearchable`, {});

const publishListing = async (id) => axios.post(`${BASE_URL_API}/spaces/${id}/searchable`, {});

const blockDates = async (id, start, end, description) =>
  axios.post(`${BASE_URL_API}/spaces/${id}/blockdaterange`, {
    start,
    end,
    description,
  });

const unblockDates = async (spaceId, id) =>
  axios.post(`${BASE_URL_API}/spaces/${spaceId}/unblockdaterange`, {
    id,
  });

const syncGoogleCalendar = async (dates) =>
  new Promise((resolve, reject) => {
    resolve("OK");
  });

const contractSignGenerate = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/contractsigngenerate`);

const payBooking = async ({ bookingId, method, url, description }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/pay`, {
    method,
    url,
    description,
  });

const getSpotsCategoriesByAsset = async (assetId) => {
  return axios.get(`${BASE_URL_API}/tagging/tags/category/slug/spots-asset-${assetId}`);
};

const setSpaceCategories = async (spaceId, tags) => {
  return axios.post(`${BASE_URL_API}/tags/space/${spaceId}`, { tags });
};

const getAdminSpaces = async (numberPerPage, pageNumber, filters = {}, sort) => {
  const stringFilters = handleSpaceFilters(filters);

  const sortKey = sort && sort.name === "location" ? "type.name" : sort.name;
  const sortedParams = sort && sort.name ? `&orderBy=${sortKey}&orderDir=${sort.direction}` : ``;

  return axios.get(
    `${BASE_URL_API}/spaces/admin/filter?page_size=${numberPerPage}&page=${pageNumber}${stringFilters}${sortedParams}`,
  );
};

const setBookingCategory = (bookingId, bookingCategoryId) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/setcategory`, {
    booking_category_id: bookingCategoryId,
  });

const setIncomeBookingCategory = (bookingId, incomeBookingCategoryUid) =>
  axios.patch(`${BASE_URL_API}/bookings/${bookingId}/categoryincome/${incomeBookingCategoryUid}`, {
    headers: { "Content-Type": "application/json" },
  });

const getSpaceServices = async () => axios.get(`${BASE_URL_API}/space/services?page_size=9999`);

const getAsset = async (id) => axios.get(`${BASE_URL_API}/assets/${id}`);

const updateAsset = async (id, inputData) => {
  const data = { ...inputData };
  data.meta_data = data.meta_data || [];

  if (data.seo === null) {
    delete data.seo;
  }

  if (data.seo_title === null) {
    delete data.seo_title;
  }

  if (data.region_id === null) {
    delete data.region_id;
  }

  return axios.patch(`${BASE_URL_API}/assets/${id}`, data);
};

const getAssetSeasons = async (assetId) => {
  return axios.get(`${BASE_URL_API}/seasons/${assetId}`);
};

const postAssetSeasons = async (assetId, data) => {
  return axios.post(`${BASE_URL_API}/seasons/${assetId}`, data);
};

const postAssetSeasonInterval = async (assetId, data) => {
  return axios.post(`${BASE_URL_API}/seasons/${assetId}/interval`, data);
};

const patchAssetSeason = async (id, data) => {
  return axios.patch(`${BASE_URL_API}/seasons/${id}`, data);
};

const deleteAssetSeason = async (id, deleteAction) => {
  const deleteActionParam = deleteAction ? `?deleteaction=${deleteAction}` : "";
  return axios.delete(`${BASE_URL_API}/seasons/${id}${deleteActionParam}`);
};

const updateSpaceActivityCoefficient = (bookingId, spaceActivityCoefficientId) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/updatespaceactivitycoefficient`, {
    space_activity_coefficient_id: spaceActivityCoefficientId,
  });

const updateSpaceActivityCoefficients = async (data) =>
  axios.post(`${BASE_URL_API}/spaceactivity/massupdate/`, {
    data,
  });

const postAdSpace = async (data) => axios.post(`${BASE_URL_API}/adspaces`, data);

const patchAdSpace = async (id, data) => axios.patch(`${BASE_URL_API}/adspaces/${id}`, data);

const postAdSpaceTags = async (id, data) => axios.post(`${BASE_URL_API}/tags/adspace/${id}`, data);

const getAdSpaceByIdAdmin = async (id) => axios.get(`${BASE_URL_API}/admin/adspaces/${id}`);

const getAdspaceBlockedDateRanges = async (id) => axios.get(`${BASE_URL_API}/adspaces/${id}/blockeddateranges`);

const blockAdSpaceDate = async (id, start, end, description) =>
  axios.post(`${BASE_URL_API}/adspaces/${id}/blockdaterange`, {
    start,
    end,
    description,
  });

const unblockAdSpaceDate = async (adSpaceId, id) =>
  axios.post(`${BASE_URL_API}/adspaces/${adSpaceId}/unblockdaterange/${id}`, {});

const getAdSpacesUses = async () => axios.get(`${BASE_URL_API}/adspaces/uses`);

const getBookingBookablesCategories = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/bookables`);

const getAvailableBookableCategories = async () => axios.get(`${BASE_URL_API}/bookables/categories`);

const getBookingNotes = async ({ bookingId, wholeChain = true }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/notes?wholeChain=${wholeChain}`);

const saveBookingNotes = async ({ bookingId, title, note }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/notes`, {
    title,
    note,
  });

const getAdSpacesCategories = async () => {
  return axios.get(`${BASE_URL_API}/tagging/tags/category/slug/adspace-categories`);
};

const getAdminAdSpaces = async ({ numberPerPage = getRowsFromWindowSize(), page = "", sort = {}, filters = {} }) => {
  const sortKey = sort && sort.name === "type" ? "adspace_type" : sort.name;
  const sortedParams = sort && sort.name ? `&orderBy=${sortKey}&orderDir=${sort.direction}` : ``;
  const stringFilters = handleAdSpaceFilters(filters);
  return axios.get(
    `${BASE_URL_API}/adspaces/admin/filter?page_size=${numberPerPage}&page=${page}${sortedParams}${stringFilters}`,
  );
};

const setAdSpaceAttachments = async (id, attachments) =>
  axios.post(`${BASE_URL_API}/attachments/order/adspace/${id}`, {
    attachments,
  });

const hideAdSpace = async (id) => axios.post(`${BASE_URL_API}/adspaces/${id}/nonsearchable`, {});

const publishAdSpace = async (id) => axios.post(`${BASE_URL_API}/adspaces/${id}/searchable`, {});

const getCustomers = async ({ companyId }) => axios.get(`${BASE_URL_API}/customers?company_id=${companyId}`);

const getInvitations = async ({ companyId }) => axios.get(`${BASE_URL_API}/companies/${companyId}/invitations`);

const updateUser = async (userId, fieldToEdit) => axios.patch(`${BASE_URL_API}/user/${userId}`, fieldToEdit);

const deleteUserInvitation = async (invitationId) => axios.delete(`${BASE_URL_API}/userinvitations/${invitationId}`);

const getCompany = async ({ id, taxId, countryId, commercialName }) => {
  let search = "";
  search += taxId ? `tax_id=${taxId}&` : "";
  search += countryId ? `country_id=${countryId}&` : "";
  search += commercialName ? `commercial_name=${commercialName}&` : "";

  return axios.get(`${BASE_URL_API}/companies/${id}`);
};

const getCompanyRatings = async ({ companyId }) => axios.get(`${BASE_URL_API}/companies/${companyId}/ratings`);

const getCompanyAverageRating = async ({ companyId }) =>
  axios.get(`${BASE_URL_API}/companies/${companyId}/ratingsaverage`);

const getCompanies = async ({ page, sort, size = getRowsFromWindowSize(), search }) => {
  let url = `${BASE_URL_API}/companies?page_size=${size}&page=${page}`;
  if (sort) {
    const sortKey = sort.name === "taxId" ? "tax_id" : sort.name;
    url += `&orderBy=${sortKey}&orderDir=${sort.direction}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return axios.get(url);
};

const createCompany = async ({ name, taxId, countryId, isAgency }) =>
  axios.post(`${BASE_URL_API}/companies`, {
    name,
    thumb_url: "",
    tax_id: taxId,
    country_id: countryId,
    is_agency: isAgency,
  });

const getContractModel = async (contractTemplateId) =>
  axios.get(`${BASE_URL_API}/contracttemplates/${contractTemplateId}`); // asset
const getAgentCandidates = async (id) => axios.get(`${BASE_URL_API}/bookings/${id}/agentcandidates`);

const assignUser = async (id, userId) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/agent`, {
    candidate_id: userId,
  });

const assignBookingUser = async (bookingId, userId) => {
  return axios.post(`${BASE_URL_API}/bookings/${bookingId}/replacedummyauthor/`, {
    user_id: userId,
  });
};

const getLandlords = ({ page, numberPerPage = getRowsFromWindowSize(), sort, filters }) => {
  let url = `${BASE_URL_API}/landlords?page_size=${numberPerPage}&page=${page}`;
  if (sort) {
    url += `&orderBy=${sort.name}&orderDir=${sort.direction}`;
  }
  if (filters.countries) {
    url += `&countries=${filters.countries.join(",")}`;
  }
  return axios.get(url);
};

const getLandLord = (id) => axios.get(`${BASE_URL_API}/landlords/${id}`);

const followBooking = (id) => axios.post(`${BASE_URL_API}/bookings/${id}/follow/`, {});

const unfollowBooking = (id) => axios.post(`${BASE_URL_API}/bookings/${id}/unfollow/`, {});

const setBookingAdSpaces = (id, adSpaces) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/updateadspaces/`, {
    adspace_ids: adSpaces.map((ad) => ad.id).join(","),
  });

const setBookingPaymentPlan = (id, paymentPlan) =>
  axios.post(`${BASE_URL_API}/bookings/${id}/setpaymentplan/`, {
    payment_plan: paymentPlan,
  });

const downloadConsultData = async ({
  exportFormat = "xls",
  state = CONSULT_STATUS.IN_PROGRESS,
  sort = {},
  search = "",
  filters = {},
  columns,
}) => {
  let stringFilters = "";
  if (filters.assets && filters.assets.length > 0 && !filters.assets.some((at) => at.id === "all"))
    stringFilters += `&assets=${filters.assets.map((a) => a.id).join(",")}`;

  const sortedParams = sort.name
    ? `&orderBy=${sort.name}&orderDir=${sort.direction}`
    : `&orderBy=updated_at&orderDir=desc`;

  const mainFilter = getFilterFromState(state);
  const columnsActive = adapterColumnsBookings(columns);
  return axios.get(
    `${BASE_URL_API}/export/bookings?exportFormat=${exportFormat}${sortedParams}&${mainFilter}&columns=${columnsActive}&search=${search}${stringFilters}`,
    {
      responseType: "blob",
    },
  );
};

const downloadSpaceData = ({ exportFormat = "xls", filters, sort, columns }) => {
  const stringFilters = handleSpaceFilters(filters);
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActive = adapterColumnsSpaces(columns);
  return axios.get(
    `${BASE_URL_API}/export/spaces?exportFormat=${exportFormat}${stringFilters}${sortedParams}&columns=${columnsActive}`,
    {
      responseType: "blob",
    },
  );
};

const downloadLandlordsData = ({ exportFormat = "xls", countries, sort, columns }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActive = adapterColumnsLandlords(columns);
  return axios.get(
    `${BASE_URL_API}/export/landlords?exportFormat=${exportFormat}${sortedParams}&countries=${countries.join(
      ",",
    )}&columns=${columnsActive}`,
    {
      responseType: "blob",
    },
  );
};

const downloadUsersListData = ({ exportFormat = "xls", sort, columns }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActive = adapterColumnsUsers(columns);
  return axios.get(
    `${BASE_URL_API}/export/admins?exportFormat=${exportFormat}${sortedParams}&columns=${columnsActive}`,
    {
      responseType: "blob",
    },
  );
};

const createBookingTillPaid = (booking, defer_contract = 0) => {
  const prices = {};
  if (booking.paymentPlan === "Total") {
    prices.price = parseFloat(booking.price);
  } else if (booking.paymentPlan === "Monthly" || booking.paymentPlan === "Weekly") {
    prices.period_price = parseFloat(booking.price);
  }

  const bookingPayload = {
    actinbehalf: booking.company?.id,
    start: formatDateUtc(booking.startDate, "YYYY-MM-DD"),
    brand_name: booking.brandname,
    project_name: booking.projectname,
    project_details: booking.description,
    payment_plan: booking.paymentPlan,
    signature_url: booking.contract,
    signature_description: "impersonated",
    payment_method: "transfer", // techdebt??
    payment_url: booking.payment,
    payment_description: "impersonated",
    space_id: booking.space ? booking.space.id : undefined,
    adspace_ids: booking.adSpaces?.length ? booking.adSpaces.map((ad) => ad.id) : undefined,
    space_activity_coefficient_id: booking.spaceActivityCoefficient?.id,
    booking_category_id: booking.bookingCategory,
    contract_model_id: booking.contractModel.id,
    defer_contract,
    ...prices,
  };

  bookingPayload.end = formatDateUtc(booking.endDate, "YYYY-MM-DD");

  if (booking.category_income) {
    bookingPayload.category_income = booking.category_income;
  }

  return axios.post(`${BASE_URL_API}/bookings/${booking.asset.id}/booktillpaid/`, bookingPayload);
};

const downloadCompaniesData = ({ exportFormat = "xls", search = "", sort, columns }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActives = adapterColumnsCompanies(columns);
  return axios.get(
    `${BASE_URL_API}/export/companies?exportFormat=${exportFormat}&search=${search}${sortedParams}&columns=${columnsActives}`,
    {
      responseType: "blob",
    },
  );
};

const downloadAdSpaceData = ({ exportFormat = "xls", filters, sort, columns }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const stringFilters = handleAdSpaceFilters(filters);
  const columsActive = adapterColumnsAdSpaces(columns);
  console.log("columsActive", columsActive);
  debugger;
  return axios.get(
    `${BASE_URL_API}/export/adspaces?exportFormat=${exportFormat}${sortedParams}${stringFilters}&columns=${columsActive}`,
    {
      responseType: "blob",
    },
  );
};

const downloadAssetsData = ({ exportFormat = "xls", sort, columns }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActive = adapterColumnsAssets(columns);
  return axios.get(
    `${BASE_URL_API}/export/assets?exportFormat=${exportFormat}${sortedParams}&columns=${columnsActive}`,
    {
      responseType: "blob",
    },
  );
};

const addTagToEntity = ({ tag = "", taggableId = 0, taggableType = "App\\Models\\Space" }) =>
  axios.post(`${BASE_URL_API}/tags/${tag}/entity/`, {
    taggable_id: taggableId,
    taggable_type: taggableType,
  });

const deleteTagFromEntity = ({ taggableId = 0 }) => axios.delete(`${BASE_URL_API}/tags/entity/${taggableId}`);

const deleteAdSpace = (id) => axios.delete(`${BASE_URL_API}/adspaces/${id}`);

const deleteSpace = (id) => axios.delete(`${BASE_URL_API}/spaces/${id}`);

const patchUser = (userId, payload) => axios.patch(`${BASE_URL_API}/user/${userId}`, payload);

const getCompanyByBrand = async ({ companyId }) =>
  axios.get(`${BASE_URL_API}/bookings/company/${companyId}/bybrandname`);

const patchCompany = async (id, payload) => axios.patch(`${BASE_URL_API}/companies/${id}`, payload);

const api = {
  getFilterFromState,
  extendBooking,
  getSpaceBookings,
  getFeatureFlags,
  getBookingDetailedPrice,
  getBookingCanRescind,
  rescindBooking,
  setBookingSpaceUse,
  updateBookingParams,
  updateAssetInvoiceOptions,
  getAdSpaceInprogressBookings,
  setBookableItemCategory,
  getBookingInvoices,
  addBookingCreditNote,
  addBookingDebitNote,
  addBookingTransaction,
  editBookingTransaction,
  createInvoiceFromTransaction,
  generateInvoicePdf,
  generateInvoiceReprocess,
  deleteBookingTransaction,
  generateNewBookingProforma,
  getUserInvoices,
  getUsersList,
  acceptBooking,
  rejectBooking,
  bookingSpecialPrice,
  startShowingBookingPrices,
  setBookingRate,
  getAdminSpace,
  getSpotsCategoriesByAsset,
  setSpaceCategories,
  getAdminSpaces,
  postSpace,
  putSpace,
  setSpaceSpots,
  setSpaceAttachments,
  replaceBookingSignedContract,
  uploadBookingContractTemplate,
  removeBookingContractGenerated,
  removeBookingContractTemplate,
  attachFileToBooking,
  detachFileToBooking,
  sendBookingContractToDocusign,
  createBookingContract,
  createBookingContractWithTemplate,
  updateBookingContract,
  removeBookingContract,
  // deprecate
  updateContractModel,
  updateContractData,
  contractPreviewGenerate,
  hideListing,
  publishListing,
  blockDates,
  unblockDates,
  syncGoogleCalendar,
  contractSignGenerate,
  payBooking,
  getAsset,
  updateAsset,
  updateAssetOptions,
  getAssetSeasons,
  postAssetSeasons,
  postAssetSeasonInterval,
  patchAssetSeason,
  deleteAssetSeason,
  updateSpaceActivityCoefficients,
  updateSpaceActivityCoefficient,
  postAdSpace,
  patchAdSpace,
  postAdSpaceTags,
  getAdSpaceByIdAdmin,
  getAdspaceBlockedDateRanges,
  blockAdSpaceDate,
  unblockAdSpaceDate,
  getAdSpacesUses,
  getBookingBookablesCategories,
  getAvailableBookableCategories,
  getBookingNotes,
  saveBookingNotes,
  getAdSpacesCategories,
  getAdminAdSpaces,
  setAdSpaceAttachments,
  hideAdSpace,
  publishAdSpace,
  getCustomers,
  getSpaceServices,
  setBookingCategory,
  setIncomeBookingCategory,
  getCompany,
  getCompanyRatings,
  getCompanyAverageRating,
  getCompanies,
  createCompany,
  getContractModel,
  getAgentCandidates,
  assignUser,
  assignBookingUser,
  getLandlords,
  getLandLord,
  followBooking,
  unfollowBooking,
  setBookingAdSpaces,
  setBookingPaymentPlan,
  downloadConsultData,
  downloadSpaceData,
  createBookingTillPaid,
  downloadAdSpaceData,
  downloadAssetsData,
  downloadCompaniesData,
  addTagToEntity,
  deleteTagFromEntity,
  deleteAdSpace,
  deleteSpace,
  getInvitations,
  updateUser,
  patchUser,
  deleteUserInvitation,
  getCompanyByBrand,
  patchCompany,
  downloadLandlordsData,
  downloadUsersListData,
  ...authApi,
  ...invoicingReport,
  ...performanceAnalyzer,
  ...services,
  ...timelineVacancy,
  ...attachment,
  ...bookingOperation,
  ...imports,
  ...v2,
};

export default api;
