import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";


const {
  primaryColor,
  secondaryColor,
  fontFamilyReadable,
  fontFamilyBrand,
} = {
  primaryColor: "#006A4D",
  secondaryColor: "#69BE28",
  fontFamilyReadable: "Nunito Sans",
  fontFamilyBrand: "Nunito Sans",
}

let theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColor,
      // dark: will be calculated from palette.primary.main,
    },
    secondary: {
      //light: "#0066ff",
      main: secondaryColor,
      // dark: will be calculated from palette.secondary.main,
      //contrastText: "#FFF",
    },
    text: {
      primary: "#333",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    // colors
    successGreen: "#6FCF97",
    gray: "gray",
    grayC4: "#C4C4C4",
    grayLabel: "#828282",
    dark: "#333333",
    lighterGray: "#E5E5E5",
    lightGray: "#F2F2F2",
    darkGray: "darkgray",
    disabledRow: "#F1F1F1",
    error: {
      main: "#FFB8B8",
    },
    success: {
      main: "#A9E2C1",
    },
    warning: {
      main: "#F7DF94",
    },
    info: {
      main: "#F2F2F2",
    },
    white: "#FFFFFF",
    black: "#000000",
    whiteMask: "rgba(255, 255, 255, 0.65)",
    redEB57: "#EB5757",
    lightGreen: "#6FCF97",
    darkGreen: "#27AE60",
    magenta: "#FF00FF",
    yellow: "#F2C94C",
    lightYellow: "#F7DF94",
    red: "#FF3764",
    lightRed: "#FF92B0",
    lighterRed: "#FFB8B8",
  }, //oswald para titulos
  // raleway textos
  typography: {
    fontSize: 11,
    h1: {
      fontFamily: fontFamilyReadable,
      fontSize: "20px",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: fontFamilyReadable,
      fontSize: "18px",
      fontWeight: 400,
    },
    h3: {
      fontFamily: fontFamilyReadable,
      fontSize: "14",
      textTransform: "uppercase",
      fontWeight: 400,
    },
    h4: {
      fontFamily: fontFamilyReadable,
      fontWeight: "bold",
      fontSize: "14px",
    },
    h5: {
      fontFamily: fontFamilyBrand,
      fontSize: "1.1rem",
    },
    h6: {
      fontFamily: fontFamilyBrand,
      fontSize: "0.9rem",
      lineHeight: 1.2,
    },
    subtitle1: {
      fontWeight: 600,
      fontFamily: fontFamilyReadable,
      fontSize: "14px",
    },
    subtitle2: {
      fontFamily: fontFamilyReadable,
      textTransform: "uppercase",
    },
    body1: {
      // texto chat
      fontFamily: fontFamilyReadable,
      fontWeight: 400,
      fontSize: 13,
    },
    body2: {
      // parrafo texto en tablas
      fontFamily: fontFamilyReadable,
      fontWeight: 400, // head en tablas bold
      fontSize: "11px",
    },
    button: {
      fontFamily: fontFamilyReadable,
      fontWeight: "bold",
      fontSize: "13px",
      textTransform: "none",
    },
    caption: {
      //smal notification
      fontFamily: fontFamilyReadable,
      fontWeight: 600,
      fontSize: "11px",
    },
    overline: {
      fontFamily: fontFamilyReadable,
      fontSize: 11,
    },
  },
  borders: {
    0: "0",
    1: "1px solid black",
    2: "1px solid #717171",
    3: "2px solid #717171",
    4: "3px solid #ff3764",
    5: "1px solid #F1F1F1",
    6: "1px solid #C4C4C4",
  },
  zIndex: {
    drawer: 999
  }
});

theme = {
  ...theme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          overflowX: "hidden",
        },
        body: {
          backgroundColor: "white",
          //paddingRight: "0 !important",
        },
        ".ck.ck-balloon-panel": {
          zIndex: "9999 !important",
        }
      },
    },
    MuiButton: {
      label: {
        fontSize: 13,
        fontWeight: "500",
        letterSpacing: "0.0075em",
        lineHeight: 1.2,
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        borderRadius: 5,
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
        "&.Mui-focused": {
          color: "#000000",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      root: {
        paddingTop: theme.spacing(1),
      },
      underline: {
        "&:before": {
          display: "none",
        },
        "&:after": {
          borderColor: "white !important",
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid #000000",
      },
    },
    MuiTab: {
      root: {
        "&.Mui-selected": {
          color: "#ff3764",
        },
      },
      wrapper: {
        textTransform: "none",
        fontSize: "21px",
        fontFamily: fontFamilyBrand,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "31px",
        color: "black",
      },
    },
    MuiCheckbox: {
      root: {
        color: "transparent",
      },
    },
    MuiTextField: {},
    // table
    MuiTableCell: {
      head: {
        textTransform: "uppercase",
        textAlign: "center",
        borderLeft: 0,
        fontWeight: 700,
        fontSize: "11px",
        "& > span": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-child(2) > span": {
          justifyContent: "center",
        },
        // this is to render the same style to the unsortable table head columns
        "& > div": {
          cursor: "default",
        }
      },
      root: {
        overflow: "hidden",
        padding: 0,
        cursor: "pointer",
        border: 0,
        fontSize: "11px",
        fontWeight: 400,
        textAlign: "center",
      },
      sizeSmall: {
        paddingLeft: 5,
      },
    },
    MuiTableHead: {
      root: {},
    },
    MUIDataTableToolbar: {
      root: {
        padding: 0,
      },
      actions: {
        display: "flex",
        flexDirection: "row-reverse",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center"
      },
    },
    MuiChip: {
      root: {
        fontSize: 14,
        height: 14,
      }
    },
    MuiTableRow: {
      root: {
        maxHeight: 22,
        height: 22,
        "&:nth-child(even)": {
          backgroundColor: theme.palette.lightGray,
        },
        "&:nth-child(odd)": {
          backgroundColor: theme.palette.lighterGray,
        },
        "&:nth-child(odd):hover": {
          backgroundColor: `${theme.palette.primary.light}`,
          "& > td": {
            color: theme.palette.primary.contrastText
          }
        },
        "&:nth-child(even):hover": {
          backgroundColor: `${theme.palette.primary.light}`,
          "& > td": {
            color: theme.palette.primary.contrastText
          }
        },
        "&.row-selected": {
          backgroundColor: `${theme.palette.primary.light}`,
          "& > td": {
            color: theme.palette.primary.contrastText
          }
        }
      },
    },
    MUIDataTableBodyCell: {
      root: {
        lineHeight: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
      }
    },
    MuiTableFooter: {
      root: {
        "& > tr.MuiTableRow-footer": {
          backgroundColor: theme.palette.white,
        },
        "& > tr.MuiTableRow-footer:hover": {
          backgroundColor: theme.palette.white,
        },
      }
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: "bold",
        fontFamily: fontFamilyReadable,
        lineHeight: 1.4,
      },
    },
    PrivateSwitchBase: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiStepLabel: {
      root: {
        cursor: "pointer",
      },
    },
    MuiAlertTitle: {
      root: {
        paddingTop: 3,
      }
    },
    MuiAlert: {
      message: {
        color: "#333333",
      },
      action: {
        color: "#333333",
        alignItems: "center",
        position: "relative",
      },
      root: {
        padding: "4px 16px",
      }
    },
    MuiPaginationItem: {
      root: {
        height: 25,
        width: 25,
        minWidth: 25,
      }
    },
    MuiDialogContent: {
      root: {
        maxHeight: 500,
      }
    },
  },
};

theme = responsiveFontSizes(theme, {
  variants: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
  ],
});
export default theme;
