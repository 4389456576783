module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../plugins/bac-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":100,"enabled":true,"rum":{"applicationId":"3f593f70-711e-4836-b004-75a3429aefd7","clientToken":"pub557089513ee96806f1ea8fa99563403e"},"logs":{"clientToken":"pub557089513ee96806f1ea8fa99563403e"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b6b39ada1ee14634e5e1af57a0c4cdd1"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en-cbreau","locales":"es en fr pt es-cbreau en-cbreau pt-cbreau fr-cbreau","configPath":"/codebuild/output/src4143667247/src/src/locales/config.json"},
    },{
      plugin: require('../plugins/bac-gatsby-theme-i18n-lingui/gatsby-browser.js'),
      options: {"plugins":[],"localeDir":"./src/locales"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://au.findyourspot.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZPHH4Y2QY9"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N4TV5BG7","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
