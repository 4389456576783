import axios from "axios";
import { formatDateUtc } from "../utils/dateUtils";
import { getRowsFromWindowSize, getBiggerRowsFromWindowSize } from "../utils";
import { adapterColumnsInvoiceByUser, adapterColumnsInvoicesbyyear } from "../utils/adapter-columns";
import config from "../config.js";

const BASE_URL_API = `${config.API_URL}/api/v1`;

const handleInvoiceFilters = (filters) => {
  let stringFilters = "";

  if (filters.assets && filters.assets.length > 0 && !filters.assets.some((at) => at.id === "all"))
    stringFilters += `&assets=${filters.assets.map((a) => a.id).join(",")}`;
  if (filters.spaces && filters.spaces.length > 0 && !filters.spaces.some((sp) => sp.id === "all"))
    stringFilters += `&space=${filters.spaces.map((a) => a.id).join(",")}`;
  if (filters.companies && filters.companies.length > 0 && !filters.companies.some((cp) => cp.id === "all"))
    stringFilters += `&companies=${filters.companies.map((a) => a.id).join(",")}`;
  if (filters.start) stringFilters += `&start=${formatDateUtc(filters.start, "YYYY-MM-DD")}`;
  if (filters.end) stringFilters += `&end=${formatDateUtc(filters.end, "YYYY-MM-DD")}`;
  if (filters.year) stringFilters += `&year=${filters.year}`;
  if (filters.bookings && filters.bookings.length > 0 && !filters.bookings.some((bk) => bk.id === "all"))
    stringFilters += `&bookings=${filters.bookings.map((b) => b.id).join(",")}`;
  if (filters.status && filters.status.length && !filters.status.some((st) => st.id === "all"))
    stringFilters += `&status=${filters.status.map((b) => b.id).join(",")}`;
  if (
    filters.bookingCategories &&
    filters.bookingCategories.length > 0 &&
    !filters.bookingCategories.some((bc) => bc.id === "all")
  )
    stringFilters += `&categories=${filters.bookingCategories.map((bc) => bc.id).join(",")}`;
  if (filters?.search?.length) stringFilters += `&search=${filters.search}`;

  return stringFilters;
};

// TECHDEBT remove after https://bookacorner.atlassian.net/browse/BAC-3236
const handleBookingInvoiceFilters = (filters) => {
  let stringFilters = "";

  if (filters.assets && filters.assets.length > 0)
    stringFilters += `&assets=${filters.assets.map((a) => a.id).join(",")}`;
  if (filters.spaces && filters.spaces.length > 0)
    stringFilters += `&spaces=${filters.spaces.map((a) => a.id).join(",")}`;
  if (filters.companies && filters.companies.length > 0)
    stringFilters += `&companies=${filters.companies.map((a) => a.id).join(",")}`;
  if (filters.start) stringFilters += `&start=${formatDateUtc(filters.start, "YYYY-MM-DD")}`;
  if (filters.end) stringFilters += `&end=${formatDateUtc(filters.end, "YYYY-MM-DD")}`;
  if (filters.year) stringFilters += `&year=${filters.year}`;
  if (filters.bookings && filters.bookings.length > 0)
    stringFilters += `&booking=${filters.bookings.map((b) => b.id).join(",")}`;
  if (filters.status && filters.status.length && !filters.status.some((st) => st.id === "all"))
    stringFilters += `&castatus=${filters.status.map((b) => b.id).join(",")}`;
  if (filters.bookingCategories && filters.bookingCategories.length > 0)
    stringFilters += `&categories=${filters.bookingCategories.map((bc) => bc.id).join(",")}`;

  return stringFilters;
};

const paramToUpdate = (type, date, concept, price) => {
  const payload = {};
  switch (type) {
    case "date":
      payload[type] = formatDateUtc(date, "YYYY-MM-DD");
      break;
    case "concept":
      payload[type] = concept;
      break;
    case "price":
      payload[type] = price;
      break;
  }
  return payload;
};

const mapSortInvoices = (name) => {
  switch (name) {
    case "price":
      return "net_total";

    case "property_code":
      return "asset_data.property_code";

    case "lease_id":
      return "asset_data.lease_id";

    case "category_income_code":
      return "booking_data.category_income_code";

    case "category_income_name":
      return "booking_data.category_income_name";
    default:
      return name;
  }
};

export const getInvoicesByUserAndCreditNotesReport = async ({
  filters = {},
  sort,
  pageSize = getRowsFromWindowSize(),
  page = 1,
}) => {
  const stringFilters = handleInvoiceFilters(filters);
  const sortKey = sort && mapSortInvoices(sort.name);
  const sortedParams = sort && sort.name ? `&orderBy=${sortKey}&orderDir=${sort.direction}` : ``;

  return axios.get(
    `${BASE_URL_API}/invoices/byuser/currentaccount?page_size=${pageSize}&page=${page}${stringFilters}${sortedParams}`,
  );
};

export const getBookingInvoicesByYearAndCreditNotesReport = async ({
  filters = {},
  sort,
  pageSize = getRowsFromWindowSize(),
  page = 1,
}) => {
  const stringFilters = handleBookingInvoiceFilters(filters);
  const sortKey = sort.name === "total" ? "account.total" : sort.name;
  const sortedParams = sort && sort.name ? `&orderBy=${sortKey}&orderDir=${sort.direction}` : ``;

  return axios.get(
    `${BASE_URL_API}/bookings/currentaccountbyyear/?page_size=${pageSize}&page=${page}${stringFilters}${sortedParams}`,
  );
};

export const updateInvoice = async ({ invoiceId, date = null, concept = "", price, type = "" }) => {
  const payload = paramToUpdate(type, date, concept, price);
  return axios.patch(`${BASE_URL_API}/bookings/invoices/${invoiceId}/update`, payload);
};

export const updateCreditNote = async ({ creditNoteId, date = null, concept = "", price, type = "" }) => {
  const payload = paramToUpdate(type, date, concept, price);
  return axios.patch(`${BASE_URL_API}/bookings/creditnotes/${creditNoteId}/update`, payload);
};

export const uploadInvoiceReceipt = async ({ invoiceId, url }) => {
  return axios.post(`${BASE_URL_API}/bookings/invoices/${invoiceId}/setreceipt/`, {
    receipt_url: url,
    // TODO: hardcoded for now
    payment_method: "transfer",
  });
};

export const markInvoiceAsPaid = async ({ invoiceId, url = null }) => {
  return axios.post(`${BASE_URL_API}/bookings/invoices/${invoiceId}/markaspaid`, {});
};

export const markInvoiceAsConfirmed = async ({ invoiceId }) => {
  return axios.post(`${BASE_URL_API}/bookings/invoices/${invoiceId}/confirm`, {});
};

export const markCreditNoteAsConfirmed = async ({ creditNoteId }) => {
  return axios.post(`${BASE_URL_API}/bookings/creditnotes/${creditNoteId}/confirm`, {});
};

export const getInvoicesByUserAndCreditNotesReportFilters = async () => {
  return axios.get(`${BASE_URL_API}/invoices/byuser/currentaccount/filters`);
};

export const getBookingInvoicesByYearAndCreditNotesReportFilters = async () => {
  return axios.get(`${BASE_URL_API}/bookings/currentaccountbyyear/filters`);
};

export const downloadBookingInvoicesByUserData = ({ exportFormat = "xls", filters, sort, columns }) => {
  const stringFilters = handleBookingInvoiceFilters(filters);
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const colmunsActive = adapterColumnsInvoicesbyyear(columns);
  return axios.get(
    `${BASE_URL_API}/export/bookings/invoicesbyyear?exportFormat=${exportFormat}${stringFilters}${sortedParams}&columns=${colmunsActive}`,
    {
      responseType: "blob",
    },
  );
};

export const downloadInvoicesByUserData = ({ exportFormat = "xls", filters, sort, columns }) => {
  const stringFilters = handleInvoiceFilters(filters);
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;
  const columnsActive = adapterColumnsInvoiceByUser(columns);
  return axios.get(
    `${BASE_URL_API}/export/invoicesbyuser?exportFormat=${exportFormat}${stringFilters}${sortedParams}&columns=${columnsActive}`,
    {
      responseType: "blob",
    },
  );
};

export const attachFileToInvoice = async (id, file) => {
  return axios.post(`${BASE_URL_API}/bookings/invoices/${id}/addattachment/`, {
    attachment_url: file,
  });
};

export const deleteInvoice = async (invoiceId) => {
  return axios.delete(`${BASE_URL_API}/bookings/invoices/${invoiceId}`);
};

export const deleteCreditNotes = async (creditNoteId) => {
  return axios.delete(`${BASE_URL_API}/bookings/creditnotes/${creditNoteId}`);
};

const mapSortPendingTransactions = (name) => {
  switch (name) {
    case "bookingId":
      return "booking_id";

    case "asset":
      return "booking.asset.name";

    case "company":
      return "booking.brand_name";

    default:
      return name;
  }
};

export const getPendingTransactions = async ({
  start,
  end,
  sort,
  pageSize = getBiggerRowsFromWindowSize() - 3,
  page,
}) => {
  const sortedParams =
    sort && sort.name ? `&orderBy=${mapSortPendingTransactions(sort.name)}&orderDir=${sort.direction}` : ``;

  let stringFilters = "";
  if (start) stringFilters += `&start=${formatDateUtc(start, "YYYY-MM-DD")}`;
  if (end) stringFilters += `&end=${formatDateUtc(end, "YYYY-MM-DD")}`;

  return axios.get(
    `${BASE_URL_API}/invoicetransactions/byuser?page_size=${pageSize}&page=${page}${stringFilters}${sortedParams}`,
  );
};
